<template>
  <div>
    <a-input v-if="disabled" size="large" disabled :value="inputValue" />
    <div v-else class="smart-time-field ant-input ant-input-lg" :class="{ 'has-error': !isValid }">
      <div>
        <vue-mask class="input" :id="inputId" v-model="inputValue" mask="00:00" :raw="false"
          :options="{ placeholder, selectOnFocus: true }">
        </vue-mask>
      </div>
      <div>
        <span><clock-circle-outlined style="color: #0000007A" /></span>
      </div>
      <!-- <div class="suffix" v-if="suffix">
        {{ suffix }}
      </div> -->
    </div>
  </div>
</template>

<script>
import { ClockCircleOutlined, } from '@ant-design/icons-vue';

import { computed, ref } from "@vue/reactivity";
import vueMask from 'vue-jquery-mask';
import moment from "moment";
import { onMounted, unref, watch } from "@vue/runtime-core";

export default {
  name: 'SmartTimeField',
  props: {
    modelValue: String, // model
    valid: Boolean, // model
    placeholder: { type: String, default: '--:--' },
    suffix: String,
    disabled: Boolean,
    asDuration: Boolean,
    inputId: String,
  },
  emits: ['update:modelValue', 'update:valid', 'change'],
  setup(props, { emit }) {
    const modelValueProps = computed(() => props.modelValue);
    const modelValidProps = computed(() => props.valid);
    const inputValueLocal = ref('');
    const isValid = ref(true);

    function checkValid() {
      // Ничего не введено - валидно
      if (!unref(inputValue)) return true;
      if (unref(inputValue)?.length < 5) return false;
      // Если определяем длительность, то она может быть очень большой, проверяй на duration
      if (props.asDuration) {
        return unref(inputValue)?.length === 5 && moment.duration(unref(inputValue)).isValid();
      } else {
        return unref(inputValue)?.length === 5 && moment(unref(inputValue), 'HH:mm').isValid();
      }
    }

    onMounted(async () => {
      if (props.modelValue) {
        inputValueLocal.value = props.modelValue;
        isValid.value = checkValid();
      }
    });

    const inputValue = computed({
      get: () => inputValueLocal.value,
      set: (newTime) => {
        // Если значения не изменились или изменились с одного незначащего значения на другое
        if ((!newTime && !unref(inputValueLocal)) || newTime === unref(inputValueLocal)) return;
        inputValueLocal.value = newTime;
        isValid.value = checkValid();

        // Если ввод окончен и всё корректно, отправляй наверх
        if (0 < newTime?.length && newTime?.length < 5) return;
        if (unref(isValid)) {
          emit('update:modelValue', newTime);
          emit('change', newTime);
        } else {
          emit('update:modelValue', null);
          emit('change', null);
        }
      }
    });

    watch(isValid, (newVal) => emit('update:valid', newVal));

    // Меня значение, если изменилось извне
    watch(modelValueProps, (newVal) => {
      inputValueLocal.value = newVal;
    });

    watch(modelValidProps, (newVal) => {
      isValid.value = newVal;
    });

    return { inputValue, isValid }
  },
  components: {
    vueMask,
    ClockCircleOutlined
  }
}
</script>

<style lang="less">
.smart-time-field {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  input {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background: none;
    letter-spacing: 1px;
    width: 100%;
    padding: 0;
  }

  .suffix {
    color: @grayscale-gray;
    font-size: 12px;
    padding-left: 1rem;
    pointer-events: none;
    white-space: nowrap;
  }
}
</style>
